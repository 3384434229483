$textColor: #2e2b63;
$white: #ffffff;
$black: #000000;
$gray: #e0e0e0;
$error: #ff1e1e;
$textError: #161432;
$superpostBackground: linear-gradient(
  90deg,
  rgba(28, 37, 98, 1) 20%,
  rgba(200, 143, 188, 1) 110%
);
$blogpostBackground: linear-gradient(
  72.49deg,
  #461a77 0%,
  #043e80 0.01%,
  #3c6899 61.43%,
  #7c99bb 86.88%,
  #bed0e6 100%
);
$liveStreamBackground: linear-gradient(45deg, #6686dd 0%, #b33232 100%);
$widgetPostBackground: linear-gradient(45deg, #360033 20%, #0b8793 110%);
$likeButton: #ff0000;
$linkColor: #5ecaf4;

//Inner container maw-width
$maxWidthInnerContainerBig: 1760;
$maxWidthInnerContainerMedium: 1480;
//Min resolution for big content
$minWidthForBiggerContentSize: 2201;

//Light
//Main
$mainColorLight: #2e2b63;
$mainColorRevertLight: #ffffff;
//$lightFon: #F2F2F2;
$lightFon: #fbfbfb;
$lightShadow: rgba(0, 0, 0, 0.05);
$boxColorLight: #ffffff;
$boxColorLightRevert: #1c1b39;
$boxColorLight_2: #f7f7f7;
$boxColorLight_3: #ffffff;
$boxColorLight_4: #f5f5f5;
$boxColorLight_5: #f7f7f7;
$boxColorLight_6: #f5f5f5;
$lightText: #2e2b63;
$lightTextRGB: 46, 43, 99;
$hoverMenuItemColorLight: #f7f7f7;
//Background Image
$backImage2kLight: '../images/back2k.svg';
$backImageWithOutDonutsLight: '../images/backWithOutDonuts.svg';
$backImageMobLight: '../images/backgrmob.svg';
//Page FAQ
$colorItemActiveMenuFAQLight: rgba(17, 38, 161, 0.1);
//Burger Menu in the Header
$backBurgerMenuLight: #ffffff;
$backBoxBurgerMenuLight: #ffffff;
$borderBurgerMenuLight: 1px solid rgba(46, 43, 99, 0.1);
$linkHoverBurgerItemLight: rgba(46, 43, 99, 0.06);
//Calendar
$calendarButtonHoverFocusLight: rgb(230, 230, 230);
$calendarButtonDisabledLight: #f0f0f0;
$calendarTextDisabledLight: rgba(46, 43, 99, 0.6);
//Live Feed
$liveFeedBtnBackLight: rgba(224, 229, 244, 0.59);
$liveFeedBtnTextColorLight: #2e2b63;
//Text Error
$textErrorColorLight: #161432;
//Profile
$profileBackColor1024Light: #ffffff;
$socialBackColorInputLight: #f7f7f7;
$twitterCheckBackColorLight: #2e2b63;
$tabPanelBackColorLight: #f5f5f5;
$tabPanelTabActiveLight: #ffffff;
$tabPanelMobTabActiveLight: rgb(230, 230, 230);
$writeBlockTextAreaBorderLight: rgba(46, 43, 99, 0.12);
//Custom Input
$inputBackColorLight: #ffffff;
//Modal
$modalTitleColorLight: #161432;
$modalButtonBorderTextColorLight: #1f45c3;
//Menu in the Header
$menuSearchColor1400Light: #2e2b63;
//Repost on hover box shadow
$hoverRepostBoxShadowLight: rgba(0, 0, 0, 0.4);
//Bought post style
$boughtPostBackLight: #f7f7f7;
//$boughtPostShadowLight: rgba(0, 0, 0, 0.4);
//Input background disabled
$inputBackDisabledLight: #e6e6e6;
//Video chat
$backgroundVideoChatLight: #f5f5f5;
//Encrypted post
$backgroundEncryptedLight: rgba(31, 69, 195, 0.05);
$backgroundEncryptedMentionLight: rgba(31, 69, 195, 0.2);
$colorCanDecryptLight: #1f45c3;
//Repost modal
$backgroundOriginPostLight: #f7f7f7;
//Videos page
$videosBackgroundInputLight: #f5f5f5;
$videosColorInputLight: #1c1b39;
$videosBackedBackgroundLight: rgba(224, 229, 244, 0.5);
//Mention
$mentionBackgroundActiveLight: #cce7ff;
$mentionBackgroundFocusedLight: #e6f3ff;
$mentionBorderTopLight: #eee;
//Superpost
$superpostMediaIconFillLight: #9ba1a3;
$superpostMediaIconShadowLight: rgba(0, 0, 0, 0.1);
//File
$fileBackgroundLight: #e6f3ff;
//Blogpost
$blogpostPlusOpenLight: #cbcad8;
$blogpostBlockquoteBGLight: #e2f2ff; //#DFE4F4 //#E2F2FF //#e6f3ff
//Video short
$videoShortMediaIconBGLight: rgba(0, 0, 0, 0.1);
//Video stream
$videoStreamMessageBGLight: #fbfbfb;
$videoStreamCopyBoxBGLight: #f5f5f5;
$videoStreamCopyLinkColorLight: #1128a3;
$videoStreamCategoryBGLight: rgba(46, 43, 99, 0.15);
//PWA
//Footer
$PWAFooterBGLight: #f4f5fd;
$PWAEthereumIconColorLight: #141332;
$PWAAddMediaButtonsBGLight: #dfe4f4;
//AudioVideoTitleDescription
$PWAAVTDBorderColorLight: #f7f7f7;
$PWAAVTDBackgroundLight: #f7f7f7;
$PWAButtonBlue2ColorLight: #1128a3;

//Dark
//Main
$mainColorDark: #ffffff;
$mainColorRevertDark: #2e2b63;
$darkFon: #151433;
$darkShadow: #151433;
$boxColorDark: #1c1b39;
$boxColorDarkRevert: #ffffff;
$boxColorDark_2: #141332;
$boxColorDark_3: #171635;
$boxColorDark_4: #171635;
$boxColorDark_5: #15163f;
$boxColorDark_6: #282752;
$darkText: #ffffff;
$darkTextRGB: 255, 255, 255;
$hoverMenuItemColorDark: linear-gradient(
  131.69deg,
  #1f45c3 6.46%,
  #0e219b 100%
);
//Background Image
$backImage2kDark: '../images/back2kDark.svg';
$backImageWithOutDonutsDark: '../images/backWithOutDonutesDark.svg';
$backImageMobDark: '../images/backWithOutDonutesDark.svg';
//Page FAQ
$colorItemActiveMenuFAQDark: linear-gradient(
  131.69deg,
  #1f45c3 6.46%,
  #0e219b 100%
);
//Burger Menu in the Header
$backBurgerMenuDark: #2e2b63;
$backBoxBurgerMenuDark: #1c1b39;
$borderBurgerMenuDark: 1px solid rgba(255, 255, 255, 0.1);
$linkHoverBurgerItemDark: linear-gradient(
  131.69deg,
  #1f45c3 6.46%,
  #0e219b 100%
);
//Calendar
$calendarButtonHoverFocusDark: linear-gradient(
  131.69deg,
  #1f45c3 6.46%,
  #0e219b 100%
);
$calendarButtonDisabledDark: #151433;
$calendarTextDisabledDark: rgba(255, 255, 255, 0.6);
//Live Feed
$liveFeedBtnBackDark: rgb(40, 40, 69);
$liveFeedBtnTextColorDark: #2e2b63;
//Text Error
$textErrorColorDark: #ffffff;
//Profile
$profileBackColor1024Dark: #151433;
$socialBackColorInputDark: #151433;
$twitterCheckBackColorDark: #151433;
$tabPanelBackColorDark: #171635;
$tabPanelTabActiveDark: #1c1b39;
$tabPanelMobTabActiveDark: #151433;
$writeBlockTextAreaBorderDark: rgba(255, 255, 255, 0.12);
//Custom Input
$inputBackColorDark: #1c1b39;
//Modal
$modalTitleColorDark: #ffffff;
$modalButtonBorderTextColorDark: #ffffff;
//Menu in the Header
$menuSearchColor1400Dark: #ffffff;
//Repost on hover box shadow
$hoverRepostBoxShadowDark: rgb(231, 227, 227);
//Bought post style
$boughtPostBackDark: #222045; //#272650
//$boughtPostShadowDark: rgba(0, 0, 0, 0.4);
//Input background disabled
$inputBackDisabledDark: #1d1a45;
//Video chat
$backgroundVideoChatDark: #21203d;
//Encrypted post
$backgroundEncryptedDark: rgba(255, 255, 255, 0.1);
$backgroundEncryptedMentionDark: rgba(255, 255, 255, 0.15);
$colorCanDecryptDark: #ffffff;
//Repost modal
$backgroundOriginPostDark: rgba(255, 255, 255, 0.05);
//Videos page
$videosBackgroundInputDark: #1c1b39;
$videosColorInputDark: #f5f5f5;
$videosBackedBackgroundDark: rgba(255, 255, 255, 0.1);
//Mention
$mentionBackgroundActiveDark: #1c1b39;
$mentionBackgroundFocusedDark: #151433;
$mentionBorderTopDark: #2e2b63;
//Superpost
$superpostMediaIconFillDark: #ffffff;
$superpostMediaIconShadowDark: rgba(255, 255, 255, 0.1);
//File
$fileBackgroundDark: #151433;
//Blogpost
$blogpostPlusOpenDark: #55546b;
$blogpostBlockquoteBGDark: #141332;
//Video short
$videoShortMediaIconBGDark: rgba(255, 255, 255, 0.1);
//Video stream
$videoStreamMessageBGDark: #161432;
$videoStreamCopyBoxBGDark: #161432;
$videoStreamCopyLinkColorDark: #ffffff;
$videoStreamCategoryBGDark: #2e2b63;
//PWA
//Footer
$PWAFooterBGDark: #1c1b39;
$PWAEthereumIconColorDark: #ffffff;
$PWAAddMediaButtonsBGDark: #1c1b39;
//AudioVideoTitleDescription
$PWAAVTDBorderColorDark: rgba(255, 255, 255, 0.12);
$PWAAVTDBackgroundDark: #1c1b39;
$PWAButtonBlue2ColorDark: #ffffff;

$bg-color: #181818;
$bg-nav-color: #202020;
$bg-color-active: #ffffff1a;
$bg-sidebar-color: #212121;
$bg-color-hover: #ffffff25;
$text-color: #f5f5f5;
$subtext-color: #aaaaaa;
$font-size: 13px;
$font-size-small: 11px;
$icon-size: 2.5rem;

//$breakpoints
$xsUP: '(min-width: 360px)';
$smUP: '(min-width: 576px)';
$mdUP: '(min-width: 768px)';
$lgUP: '(min-width: 992px)';
$IpadUP: '(min-width: 1024px)';
$xlUP: '(min-width: 1281px)';
$xxlUP: '(min-width: 1400px)';

$xsDOWN: '(max-width: 360px)';
$IPhoneDOWN: '(max-width: 375px)';
$DOWN410: '(max-width: 410px)';
$DOWN500: '(max-width: 500px)';
$smDOWN: '(max-width: 576px)';
$smMUIDOWN: '(max-width: 600px)';
$mdDOWN: '(max-width: 768px)';
$lgDOWN: '(max-width: 992px)';
$IpadDOWN: '(max-width: 1024px)';
$DOWN1120: '(max-width: 1120px)';
$DOWN1200: '(max-width: 1200px)';
$xlDOWN: '(max-width: 1281px)';
$xxlDOWN: '(max-width: 1400px)';
$DOWN1485: '(max-width: 1485px)';
$DOWN2400: '(max-width: 2400px)';
